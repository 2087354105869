 
  .terms-container {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
  }
  
  .terms-form {
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
    
  .terms-footer p {
    margin: 5px 0;
  }
  
  .terms-footer a {
    color: #888; /* Optional: lighter color for footer links */
    text-decoration: underline;
  }
  
  .ant-form-item{
    margin-bottom: 12px;
    font-weight: 500;
  }
  
  .ant-col .ant-form-item-label {
    padding-bottom: 0;
  }

.terms-form a {
    color: #1890ff; /* Ant Design's primary link color */
    text-decoration: underline; /* Underline the link */
}

.terms-form a:hover {
    color: #40a9ff; /* Change color on hover */
    text-decoration: underline; /* Keep underline on hover */
}


.terms-title {
  text-align: center;
  font-size: 24px;
  /* margin-top: 100px; */
}

.user-info {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.user-values {
  font-size: 16px;
  text-align: left;
  margin-left: 10px;
  font-weight: normal;
}

.user-prop {
  font-weight: bold;
  font-size: 16px;
  color: #555;
  text-align: left;
}

.bank-accounts-title {
  font-size: 18px;
  margin-top: 20px;
}

.bank-accounts-list {
  text-align: left;
  list-style-type: none;
  padding: 0;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background-color: #ffffff;
}

.bank-account-item {
  padding: 5px 0;
  border-bottom: 1px solid #eaeaea;
  margin-left: 5px;
}

.terms-form-button {
  width: 50%;
}

.terms-footer {
  text-align: center;
  font-size: 14px;
  color: #777;
}

.content-wrapper {
  margin-top: 20px;
}