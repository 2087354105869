/* src/components/ScheduledPayments.css */
.scheduled-payments-container {
    padding: 0px 0px 0px 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.scrollable-list {
    max-height: 400px;
    overflow-y: auto;
}
  