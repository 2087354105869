.accounts-container {
  padding: 20px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  overflow-y: auto;
}

.ant-list-item {
  padding: 0 !important;
}