.mfa-container {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mfa-content {
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.mfa-content h2 {
  margin-bottom: 20px;
}

.mfa-content button {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
}