.content-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  overflow: auto;
}

.wrapper {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 32px;
  margin-right: 16px;
}
  