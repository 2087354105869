.content-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
}

.wrapper {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    align-items: center;
    padding-top: 20px;
    overflow: auto;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    height: 32px;
    margin-right: 16px;
}