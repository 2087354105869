 
  .login-container {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }
  
  .login-form {
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .login-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .login-form-forgot {
    margin-right: auto;
  }
  
  .login-form-button {
    width: 100%;
  }
  
  .login-footer {
    text-align: center;
    margin-top: 20px;
    font-size: 12px; /* Smaller text size */
    color: #888; /* Optional: lighter color for footer text */
  }
  
  .login-footer p {
    margin: 5px 0;
  }
  
  .login-footer a {
    color: #888; /* Optional: lighter color for footer links */
    text-decoration: underline;
  }
  