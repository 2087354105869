.logo {
  height: 64px;
  margin: 0px 16px 0px 16px;
  text-align: center;
}

.logo-img {
  max-height: 100%;
  max-width: 100%;
}
  
.user-name {
  padding: 16px;
  font-size: 16px;
  text-align: center;
}

.content-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  overflow: auto;
}
  