.payees-container {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.payee-card {
  cursor: pointer;
}

.ant-list-item {
  padding: 0 !important;
}
